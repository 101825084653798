.offer-list-header {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.ui.segment img{
    border-radius:10px;
}

.offerListBlock .ui.segment {
    margin: 15px;
    border-radius: 10px;
    border: unset;
}

.new-offer {
    display: flex;
    flex-direction: column;
}
/*.new-offer label{
    margin-top:5px;
}*/
.new-offer-input {
    margin-bottom: 15px !important;
    border-radius: 0px !important;
    box-shadow: 0px 10px 30px 5px rgb(0 0 0 / 10%) !important;
    border: 1px solid rgba(227, 227, 227, 0.8);
}
.new-offer input {
    margin-bottom: 15px !important;
    border-radius: 0px !important;
    box-shadow: 0px 10px 30px 5px rgb(0 0 0 / 10%) !important;
    border: 1px solid rgba(227, 227, 227, 0.8);
    /*z-index: 100;*/
}
    .new-offer > * {
        opacity: 1 !important;
    }
    .new-offer .dropdown:not(i) {
        margin-bottom: 15px !important;
        border-radius: 0px !important;
        box-shadow: 0px 10px 30px 5px rgb(0 0 0 / 10%) !important;
        border: 1px solid rgba(227, 227, 227, 0.8);
        /*z-index: 100;*/
    }
    .new-offer textarea {
        margin-bottom: 15px !important;
        border-radius: 0px !important;
        box-shadow: 0px 10px 30px 5px rgb(0 0 0 / 10%) !important;
        border: 1px solid rgba(227, 227, 227, 0.8);
        /*z-index: 100;*/
    }

.image-upload {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.image-upload img {
    margin-top: 10px;
    width: 300px;
}
.offer-task-btn{
    margin:10px;
}
.modal-error-text {
    z-index: 3;
    color: red;
}
.offer-row-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: large;
    font-weight: 500;
    color: #868686;
    /*z-index: 100;*/
}
.offer-row-content {
    font-size: large;
    font-weight: bold;
    margin-bottom: 15px !important;
}

.offer-row-content-name {
    font-size: xx-large;
    font-weight: bold;
    color: #5956e9;
    margin-bottom: 20px !important;
}

.description {
    width: 100%;
}
    .description img {
        border-radius: 10px;
        margin-bottom: 15px;
    }

.offer-selectors-block {
    background-color: #d6d9e0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    margin-left: 10%;
    margin-right: 10%;
}
    .offer-selectors-block .selected {
        background-color: rgba(91,68,224,.8);
        color: #fff;
    }
.offer-selector {
    cursor:pointer;
    font-weight: 700;
    color: white;
    padding: 10px;
    margin: 10px;
    border-radius: 10px;
    text-align:center;
}

.modal-action-btn {
    min-height: 70px !important;
    margin: 5px !important;
    font-weight: bold !important;
    font-size:large !important;
}

.action-btn-part {
    display: flex;
    flex-direction: column;
}

.back-btn {
    color: #5956e9;
    font-size: large;
    margin-bottom: 20px;
    cursor: pointer;
    font-weight: bold;
}

#add-photo-button {
    left: 0%;
    border-radius: 10px;
    margin: 0px;
    padding: 10px;
    width: 15em;
    min-width: 18vh;
    box-shadow: none;
    border: 2px solid rgba(91, 68, 224, 0.8);
    background-color: rgb(229, 225, 255);
    position: relative;
    cursor: pointer;
}
#add-photo-button-text {
    left: 5%;
    font-weight: bold;
    font-size: large;
    color: rgba(91, 68, 224, 0.8);
    position: relative;
}
#add-photo-button-plus-border {
    top: 0.8em;
    left: 12em;
    border-radius: 5px;
    height: 22px;
    width: 22px;
    box-shadow: none;
    border: 2px solid rgba(91, 68, 224, 0.8);
    position: absolute;
    cursor:pointer;
    display: grid;
    justify-content: flex-end;
    align-items: center;
}

.modal-backgroud {
    background-color: #f2f2f2 !important;    
}
.ui.modal > .header {
    display: flex !important;
}

i.violet.icon {
    color: #5956e9 !important;
}
.ui.basic.violet.button {
    color: #5956e9 !important;
}

.offer-row {
    display: flex;
    align-items: center;
}

.offer-row .task-body{
    margin-left:10px !important;
    min-height:unset !important;
}

.offer-dimmer{
    border-radius:10px;
    height:8vh !important;
}
.counterRow{
    position: absolute;
    right: 10px;
    top: 10px;
    width: 5px;
}