
 .giftCarusel>.awssld {
    margin-top: 0px;
 --organic-arrow-thickness: 4px;
 --organic-arrow-height: 20px;
 --slider-height-percentage: 270px;
 --loader-bar-color: #851515;
 --loader-bar-height: 6px;
 --control-button-width: 7%;
 --control-button-height: 25%;
 --control-button-opacity: 0.5;
 --control-button-hover-opacity: 0.75;
 --control-button-background: transparent;
 --transition-bezier: cubic-bezier(0.5, 0.075, 0.25, 0.95);
 --slider-transition-duration: 575ms;
 --organic-arrow-color: #726767;
 --organic-arrow-border-radius: 0;
 --control-bullet-color: #a9a0a0;
 --control-bullet-active-color: #3249d2;
 --content-background-color: transparent !important;}

 .awssld__bullets button {
     padding: 0;
     display: block;
     width: 10px;
     height: 10px;
     margin: 5px;
     border-radius: 50%;
     background: var(--control-bullet-color);
     text-indent: -9999px;
     overflow: hidden;
     cursor: pointer;
     border: none;
     transition: transform 0.225s cubic-bezier(0.8, 1.35, 0.75, 1.45), background-color 0.175s ease-out;}
 
    .giftCarusel-bulets>.awssld__bullets {
     display: none !important;
     position: absolute;
     bottom: -30px;
     width: 100%;
     display: flex;
     align-items: center;
     justify-content: center;
 }
 .awssld__wrapper {
    display: block;
    overflow: hidden;
    position: relative;
    width: auto !important;
    height: 70vw;
    min-height:250px;
    margin: 20px;
}
.awssld__timer {
    background-color: transparent !important;
}
.settingsText {
    box-shadow: none !important;
}
.giftCarusel .awssld__content {
    display: block;
}

    @media all and (max-width: 500px) {
        .giftCarusel .awssld__container {
            padding-bottom: var(--slider-height-percentage);
        } }

.giftCarusel-next .awssld__controls {
    visibility: visible;
}

.giftCarusel-bullets .awssld__bullets button{
    display: none !important;
}