.app-input-btn .ui-button-background {
    color: white !important;
    background: #5956E9 !important;
    height: 50px;
    font-size: 15px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.app-tho {
    display: flex;
    width: 100%;
    grid-gap: 5px;
    gap: 5px;
    flex-wrap: wrap;
}

    .app-tho > * {
        flex: 1 1 160px;
    }

.messanger.page {
    margin-top: 40px;
    padding: 20px;
}



/*Room Row*/
#RoomRow-Container {
    border-radius: 10px;
    margin: 4px;
    margin-bottom: 15px;
    padding: 5px;
    background: rgba(255, 255, 255, 0.7) !important;
    position: relative;
    min-height: 80px;
}

.RoomRow-Photo {
    width: 71px;
    height: 70px;
    float: left;
    object-fit: cover;
    border-radius: 10px;
}

#RoomRow-body {
    min-height: 8vh;
    margin-left: 80px;
}

.RoomRow-name {
    font-weight: bold;
}

.RoomRow-consumer {
    font-size: smaller;
    font-weight: 100;
}

.RoomRow-theme {
    color: #5956E9;
    font-size: smaller;
    font-weight: 100;
}

/*MessageCard*/
.message-back-button {
    position: absolute;
    top: 40px;
    color: white;
    font-size: x-large;
    background: radial-gradient(50% 30%, rgba(0,0,0,255),rgba(0,0,0,0));
    display: flex;
    width: 35px;
    height: 54px;
    align-items: center;
    text-align: center;
    padding-left: 4px;
    margin-left: 10px;
    padding-top: 1px;
}

#message-container {
    border-radius: 10px;
    margin: 4px;
    padding: 13px;
     background: rgba(255, 255, 255, 0.7) !important;
    position: relative;
}

.message-photo {
    width: 50px;
    height: 50px;
    float: left;
    margin-left: 60px;
    margin-top: 25px;
    /* left: 10px; */
    object-fit: cover;
    border-radius: 30px;
}

#message-body {
    min-height: 8vh;
    /* margin-left: 80px; */
    padding-left: 120px;
    margin-top: 30px;
}


/*Messages*/
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.chat {
    padding-inline: 10px;
    height: 100%;
    overflow-y: scroll;
    display: flex;
    flex-direction: column-reverse;
}
.closeConv{
    position: absolute;
    right: 10px;
    top: 5px;
    width:25px;
}
.messages {
    height: 100vh;
    display: flex;
    flex-direction: column;
    background: #d7dcfaba;
}

.chat-msg-panel {
    display: flex;
    align-items: center;
    /*height: 25vh;*/
    /*max-height: 20vh;*/
    margin: 10px;
}
.chat-msg-panel .msg-area{
    width:100%;
    height:100%;
    padding-right: 50px;
}

.chat-msg-panel .msg-send{
    width:55px;
    height:55px;
    margin-left:5px;
}

.message {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.message--user-1 + .message--user-2,
.message--user-2 + .message--user-1 {
    margin-top: 1em;
}

.message--user-1 + .message--user-1,
.message--user-2 + .message--user-2 {
    margin-top: .5em;
}

.message__time {
    font-size: 10px;
    color: #5456d3;
    width: 100%;
    margin: 0 0 5px 70px;
    font-weight: bold;
}
.message__time_aponent{
    display: flex;
    justify-content: right;
    font-size: 10px;
    color: #5456d3;
    width: 100%;
    margin: 0px 0px 0px -60px;
    font-weight: bold;
}
.message__name_my {
    font-size: 10px;
    color: #f3f1f1;
    width: 100%;
}
.message__name_aponent { 
    font-size: 10px;
    color: #fff;
    text-align: right;
    padding: 0 0 0-30px;
    padding-top: -40px;
}
.message__author-pic {
    margin: 0 10px 0 0;
}

.message__photo {
    width: 50px;
    height: 50px;
    float: left;
    border-radius: 30px;
}

.message__text {
    padding: 0px 10px 10px 10px;
    border-radius: 10px;
    border: 1px solid #d8edff;
    background-color: #6756c6;
    max-width: 70%;
    word-wrap: break-word;
}

.message--user-2 {
    justify-content: flex-end;
}

    .message--user-2 .message__time {
        text-align: right;
        margin: 0 60px 5px 0;
    }

    .message--user-2 .message__author-pic {
        order: 1;
        margin: 0 0 0 10px;
    }

    .message--user-2 .message__text {
        background-color: #1176c6;
    }

    .send-image{
        position: absolute;
        right: 85px;
    }
    .chat-msg-panel .ui.button:first-child{
        background: transparent;
    }
    .unread{
        position: absolute;
        right: 10px;
        top: -10px;
        border-radius: 40px;
        padding: 10px;
        width: 40px;
        color: white;
        background-color: #10d510;
        text-align: center;
    }
