
  .rating-table{
    margin: 5%;
    width: 90%;
}

.rating-table td{
    text-align: left;
}

.rating-table th{
    text-align: center;
}

.rating-table td:first-child {
    text-align: center;
}

.rating-table td:last-child {
    text-align: center;
}

.rating-current-account td{
    background-color: #d5e6d9;
}

.ratingBlock {
    padding-top: 41px;
    padding-left: 1vh;
    padding-right: 1vh;
    overflow: auto;

}

.ui.items.segments{
    position: inherit;
}

.rating-selector {
  z-index: 1;
  width: 49%;
  float: left;
  text-align: center;
  padding: 10px;
  margin-top: 5px;
  font-weight: bold;
  font-size: larger;
  color: white;
}
.rating-selector.selected {
  z-index: 1;
  background-color: #5956E9;
  border-radius: 10px;
  color: white;
  position: fixed;
}
.rating-bacground{
  position: fixed;
  width: 90%;
  height: 50px;
  background: #5956E9;
  opacity: 0.5;
  z-index: -1;
  left: 0;
  border-radius: 10px;
  right: 0;
  margin: auto;
  margin-top: 15px;
}
.rating-selectors-block {
  margin: 10px;
}

.rating-second-selector {
  position: fixed;
  border-radius: 10px;
  color: white;
}

.rating-App-header {
    min-height: 60vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    background-color: #f7f7f7;
    position: relative;
    background-image: url('../../images/background.jpg');
    background-size: contain;
}

.rating-info {
    position: absolute;
    height: 20vh;
    width: 17vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: orange;
    font-weight: bold;
}

.rating-info > .rating-info-detail {
    font-size: calc(8px + 2vmin);
    font-weight: normal;
    text-align: center;
    margin-top: 1vh;
    margin-bottom: 1vh;
    color: #767676;
}

.rating-info.rating-info-top{
    top: 3vmax;
    width: 100%;
    left: 0;
    text-align: center;
    color: #767676;
} 

.rating-info-top-left {
    top: 20vh;
    left: 0;
    width: 15vmax;
}

.rating-info-bottom-left {
    left: 0;
    bottom: 0;
    width: 15vmax;
}

.rating-info-top-right {
    right: 0;
    top: 20vh;
    width: 15vmax;
}

.rating-info-bottom-right {
    bottom: 0;
    right: 0;
    width: 15vmax;
}

.rating-info-bottom {
    bottom: 0;
}

.rating-photo {
    border-radius: 50%;
    height: 18vh;
    width: 18vh;
    background-color: #fff;
    border: #767676 2px solid;
    object-fit: cover;
    margin-top: 5vmax;
}

.rating-photo-area {
    position: relative;
}

.rating-back-button{
    position: absolute;
    top: 35px;
    left: 0px;
    color: white;
    font-size: x-large;
    background:radial-gradient(50% 30%, rgba(0,0,0,255),rgba(0,0,0,0));
    display: flex;
    width: 35px;
    height: 54px;
    align-items: center;
    text-align: center;
    padding-left: 4px;
    margin-left: 10px;
    padding-top: 1px;
    z-index: 1;
}

  
  .row-raiting {
    margin: 10px;
    justify-content: space-between;
    display: flex;
   
  }
  .img-rating
  {
    width: 50px;
    height: 50px;
  }
  
  .ball
  {
    align-self: center;
    width: 50px;
    height: 50px;
  }
    /*
  .img-footer
  {
    min-height: 50vh;
    flex-direction: column;
    align-items: bottom;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    background-image: url('./../../images/footer-raiting.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position-x: center;

  }
*/
  .card-raiting
  {
    margin-top: 70px;
  }

  .row-1-left
  {
    display: flex;
  }
  .row-1-right
  {
    position: fixed;
    right: 90px;
    top: 60px;
    display: flex
  }

  .row-2-center
  {
    position: fixed;
    right: 50%;
    display: flex;
    top: 120px;
  }
  .row-2-right
  {
    position: fixed;
    right: 10px;
    top: 190px;
    display: flex;
  }

    .row-3-center
    {
        position: fixed;
        right: 50%;
        display: flex;
        top: 200px;
    }
  .row-3-right
  {
    position: fixed;
    right: 30px;
    top: 270px;
    display: flex;
  }

  .row-4-center
  {
    position: fixed;
    right: 40%;
    top: 310px;
  }
  .row-4-center-img
  {
    position: absolute;
    right: 60px;
    top: 30px;
  }
  .row-4-right
  {
    position: fixed;
    right: 10px;
    top: 330px;
    display: flex;
  }
  .row-4-right-width
  {
    width: 180px;
  }
 
  .img-place
  {
    width: 140px;
    height: 90px;
  }
  .row-all-left
  {
    margin-bottom: 10px;
    margin-left:10px;
  }
  .row-item-left
  {
    margin-left:50px;
  }
  .row-text-right
  {
    margin-left: 5px;
  }
  .row-4-center-margin
  {
      
      margin: 0px;
  }

  @media (max-width: 630px) 
  {
    .row-2-center
    {
        position: static;
        right: 50%;
        display: flex;
        top: 200px;
    }
    .row-3-center
    {
        position: static;
        right: 50%;
        display: flex;
        top: 200px;
    }
    .row-4-center
    {
        position: fixed;
        right: 30%;
        top: 440px;
    }
    .row-4-center-margin
    {
        display: flex;
        margin: 20px;
    }
    .row-3-right {
        position: fixed;
        right: 30px;
        top: 270px;
        display: flex;
    }
    .row-1-right {
        position: fixed;
        right: 90px;
        top: 60px;
        display: flex;
   }
  }
  @media (max-width:482px)
  {
    .row-text-all
    {
        width: 150px;
    }
    .row-3-right {
        position: fixed;
        right: 10px;
        top: 270px;
        display: flex;
    }  
    .row-1-right {
        position: fixed;
        right: 70px;
        top: 60px;
        display: flex;
    }
    .row-4-center {
        position: fixed;
        right: 10%;
        top: 440px;
    }
}


/*Рейтинг ребёнок*/
.heder{
  font-weight: 700;
  font-size: 30px;
  margin: 20px;
}
.imgfon{
  width: 70px;
}
.imgfon2{
  width: 60px;
}
.imgfon3{
  width: 180px;
}
.column-rating {
  flex: 50%;
  padding: 20px;
  text-align: center;

}
.column3-rating {
  flex: 50%;
  padding: 10px;
  text-align: center;

}
.column4-rating {
  flex: 50%;
  padding: 10px;
  text-align: center;
  position: relative;
}
.row-rating {
  display: flex;
}
.price-rating{
    background: #fffdfd;
    padding: 5px;
    border-radius: 15px;
    font-size: 19px;
    color: #5956E9;
    font-family: monospace;
    font-weight: bold;
}
.price2-rating{
    padding: 5px;
    font-size: 19px;
    color: #5956E9;
    font-family: monospace;
    font-weight: bold;
}
.price3-rating{
  position: absolute;
  right: 34px;
  top: 18px;
  font-size: 25px;
  padding: 5px;
  color: white;
  font-family: monospace;
  font-weight: bold;
}
/*Рейтинг вожатый*/

table, thead, tbody, th, td, tr {
  display: revert;
}

.ui.segments {

  border: none;
  box-shadow: none;
}

.rating-table th {
  text-align: left;
  font-weight: 400;
  color: #C9C9C9;
  padding: 10px;
}

.td1-rating{
  background: #fffdfd;
  border-radius: 5px;
  font-weight: bold;
  padding: 5px 10px;
  margin-bottom: 5px;
}
.td2-rating{
  padding-right: 50px;
  vertical-align: top;
  font-size: 11px;
  padding-left: 10px;
}
.td3-rating{
  background: #fffdfd;
  border-radius: 5px;
  font-weight: bold;
  padding: 5px 10px;
  margin-bottom: 5px;
  color: #5956E9;
  font-family: monospace;
  
}