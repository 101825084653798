table.gift-table {
    width: 100%;
    border-spacing: 0;
    margin-bottom: 0.5vh;
}

.search-input {
    width: 100%;
    margin-top: 1vh;
}

table.gift-table>tbody>td {
    border-bottom: none;
    width: 50%;
}

.giftBlock {
    padding-top: 41px;
    padding-left: 1vh;
    padding-right: 1vh;
    height: 100vh;
}

.gift-table-header {
    font-weight: bold;
}

.gift-body {
    min-height: 10vh;
    margin-left: 10vh;
}

.gift-card-photo {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: auto;
    max-width: 100%;
    object-fit: cover;
    color: white;
    max-height: 45vh;
    margin-left: auto;
    margin-right: auto;
    padding-top: 41px;
}

.gift-card-title {
    font-size: larger;
    font-weight: bold;
    text-align: center;
    margin: 10px;
}

.gift-back-button {
    top: 2vh;
    left: 8%;
    position: relative;
    color: #5956E9;
    font-size: large;
    font-weight: bold;
    cursor: pointer;
}

.gift-card-period {
    position: absolute;
    top: 31vh;
    color: white;
    text-align: center;
    font-size: smaller;
    background: radial-gradient(50% 30%, rgba(0, 0, 0, 255), rgba(0, 0, 0, 0));
    padding: 3vh;
    margin-left: -3vh;
}

.div-space-photo-and-description {
    height: 1vh;
}

/*
.gift-card-description {
    height: 53vh;
}
*/

.gift-card-description {
    max-width: 100%;
    /*height: 800px !important;*/
    /*min-height: 100% !important;*/
    height: 100vh;
    /* чтобы растянуть вниз до конца экрана */
    overflow: auto;
    justify-content: left;
    background-color: white;
    border-radius: 20px !important;
    padding: 2.7em;
}

/*
.gift-card-description-title {
    display: flex;
    justify-content: center;
    align-items: center;
}
*/

.gift-card-description-title {
    top: -1em;
    left: 5% !important;
    font-size: 1.4em !important;
    font-weight: bold;
    position: relative;
    display: flex;
    color: rgb(0, 0, 0);
}

.gift-card-description-wished {
    margin-left: 10px;
    width: 20px;
}

.gift-card-description table {
    width: 100%;
    border-collapse: collapse;
}

.gift-card-description table.gift-card-info td:first-child {
    width: 70%;
}

.gift-label {
    /*font-size: smaller;
    color: #6e6e6e;
    clear: both;*/
    /*top: 5px !important;
    position: relative;*/
    /*font-family: 'Raleway';*/
    margin: 5px;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    letter-spacing: 0.04em;
    color: #000000;
}

.gift-value {
    /*font-size: larger;
    font-weight: bold;*/
    /*font-family: 'Raleway';*/
    /*top: 5px !important;
    position: relative;*/
    margin: 5px;
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 26px;
    color: #5956E9;
}

.gift-card-description2 {
    /*top: 5px !important;
    position: relative;*/
    margin-top: 5px;
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 18px;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    letter-spacing: 0.04em;
    color: #797979;
}

.gift-wished-label {
    text-align: center;
}

.gift-button {
    margin: 5px;
}

#gift-card-notification-text {
    top: 0em;
    left: 1em !important;
    font-size: 0.85em !important;
    font-weight: bold;
    justify-content: left !important;
    position: relative !important;
    display: flex !important;
    padding-left: 40px !important;
    padding-top: 8px;
    padding-right: 8px;
    padding-bottom: 8px;
    min-width: 200px !important;
    width: 70vw !important;
    color: rgba(0, 0, 0, 0.67);
    border-radius: 10px !important;
    border: none !important;
    box-shadow: 0 0 0 500px #D3F2FF inset !important;
}

#gift-card-notification-icon {
    top: 6px;
    left: 10px !important;
    display: flex;
    position: absolute;
}

#gift-card-order-button {
    top: 1em;
    left: 0% !important;
    font-size: 1.2em !important;
    position: relative;
    justify-content: center;
    align-items: stretch;
    width: 100% !important;
    padding: 0;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    margin-bottom: 0.6rem;
    border-radius: 8px !important;
    box-shadow: 10px 30px 30px 5px rgb(236, 236, 236) !important;
}

.giftBlock .items {
    max-height: calc(94vh - 41px);
    overflow: auto;
}

.gift-commentary {
    width: 100%;
}

.gift-error-message {
    color: red;
}

.gift-param {
    padding: 1vh;
    border: 1px solid #dcdcdc;
    float: left;
    margin-right: 5px;
    border-radius: 8px;
    max-width: 98%;
    margin-bottom: 5px;
    text-align: center;
    overflow: hidden;
}

.gift-params {
    padding: 5px;
    clear: both;
    margin-bottom: 2vh;
}

.gift-card-plus-minus {
    top: -23px;
    left: 90px !important;
    position: relative;
}

.reservationComplited {
    font-size: large;
    font-weight: bold;
    color: green;
    clear: both;
    padding-top: 1em;
    text-align: center;
}

.gift-param.selected {
    border-radius: 8px !important;
    border: 1px solid rgba(34, 36, 38, 0.549) !important;
    background-color: #ffffff;
    color: #000000;
}


.gift-count {
    top: -4px;
    position: relative;
    float: left;
    font-size: large;
    font-weight: bold;
    line-height: 30px;
    padding-left: 6px;
    padding-right: 9px;
}

.gift-plus {
    float: left;
    background-color: #7DCCEC;
    ;
    width: 23px;
    height: 23px;
    display: flex;
    align-items: center;
    align-content: center;
    border-radius: 3px;
    color: white;
    font-size: 20px;
    padding-left: 3px;
    padding-top: 3px;
}

.gift-remove {
    float: left;
    background-color: #7DCCEC;
    ;
    width: 23px;
    height: 23px;
    display: flex;
    align-items: center;
    align-content: center;
    border-radius: 3px;
    color: white;
    font-size: 20px;
    padding-left: 3px;
    padding-top: 3px;
}

.gift-row {
    min-height: 305px;
    border-radius: 10px;
    background: #5956E9;
    width: 48% !important;
    float: left;
    position: relative;
    margin-bottom: 0 !important;
    margin-right: 2% !important;
    margin-top: 2% !important;
    padding: 0.5em !important;
    background-repeat: no-repeat !important;
    background-position: center center !important;
    background-size: contain !important;
}

.gift-row-info-slider {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 10px;
}

.gift-row-info {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 60px;
}

.gift-name {
    text-align: center;
    color: white;
    text-overflow: ellipsis;
    /* white-space: nowrap;*/
    overflow: hidden;
    padding-inline: 10px;
}

.gift-point {
    text-align: center;
    color: black;
}

.gift-photo {
    width: 100%;
    height: 25vh;
    border: 1px solid #f0f0f0;
    object-fit: cover;
}

.gift-r-photo {
    width: 8vh;
    float: left;
    min-height: 8vh;
    border: 1px solid #f0f0f0;
    object-fit: cover;
    border-radius: 10px;
    margin-right: 10px;
    height: 50px;
}

.gift-r-body {
    height: 120px;
    min-height: 10vmax;
    margin-left: 4em;
    margin-right: 110px;
    margin: 5px;
    display: flex;
}

.control-panel {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.gift-status {
    color: #c4c4c4;
}

.control-panel > * {
    margin: 2px;
    width: 110px !important;
}

.gift-r-name {
    font-weight: bold;
}

.gift-r-point1 {
    /*    right: 0.5em;
    top: 0.5em;
    position: absolute;*/
}

.gift-r-point2 {
    /*    right: 0.5em;
    top: 3.5em;
    position: absolute;*/
}

.mega-rounded-by-olga {
    border-radius: 15px !important;
    font-weight: bolder !important;
}

.gift-r-point button {
    padding: 5px !important;
}

.ui.segment.gift-reserved {
    margin: 15px !important;
    border-radius: 10px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
}

.gift-r-rating {
    display: flex;
    line-height: 5vh;
    flex-direction: column;
    align-items: flex-start;
}

.gift-r-rating .button {
    margin-top: 5px;
}

.gift-r-title {
    font-size: smaller;
    color: #c0c0c0;
}

.gift-status {
    /*    right: 2em;
    top: 9em;
    position: absolute;
    font-size: smaller;*/
}

.scrolling-wrapper {
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    max-width: 100vw;
}

.card {
    display: inline-block;
}

.gift-lent-title {
    display: flex;
    justify-content: center;
    background: #ffcd3a;
    min-height: 50px;
    align-items: center;
}

.wish-list-table {
    display: grid;
}

.wish-list-table td {
    width: 100vw;
}

.gift-row-lent {
    width: 48vw;
}

.gift-image-point-avaliable {
    box-shadow: 0 0 10px 10px #f2711c inset;
}

.gift-lighting-point-avaliable {
    box-shadow: 0 0 10px 10px #33d9d9 inset;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.gift-lighting-point-not-avaliable {
    box-shadow: 0 0 10px 10px #8b8b8b inset;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.gift-img-point-not-avaliable {
    -webkit-filter: grayscale(70%);
    filter: grayscale(70%);
}

.gift-avaliable {
    box-shadow: 0 0 10px 10px #f2711c inset;
    z-index: 3;
    position: absolute;
    bottom: 0px;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.gift-not-avaliable {
    -webkit-filter: grayscale(70%);
    filter: grayscale(70%);
    box-shadow: 0 0 10px 10px grey inset;
}

.image-gift {
    position: relative;
    height: 170px;
}

.image-gift-slider {
    position: relative;
    height: 50vw;
    min-height: 170px;
}

.like-background {
    border-radius: 100%;
    cursor: pointer;
    width: 40px;
    height: 40px;
    position: absolute;
    right: 10px;
    bottom: 10px;
    text-align: center;
    background: rgba(255, 255, 255, 0.7)
}

.gift-poster {
    background: #5956E9;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-end;
    flex-direction: row-reverse;
    position: relative;
    border-radius: 20px;
    padding: 5px;
}

.gift-poster-in-gift-card {
    background: #ffffff;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-end;
    flex-direction: row-reverse;
    position: relative;
    border-radius: 20px;
    padding: 5px;
}

.gift-poster-rounded {
    border-radius: 10px;
}

.gift-poster-photo {
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.gift-poster-photo img {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    object-fit: cover;
}

.gift-text {
    bottom: -60px;
    position: absolute;
    left: -5px;
    margin: 10px;
    background: #78EEDC;
    padding: 10px;
    font-weight: 900;
    font-size: 17px;
    border-radius: 10px;
}

.gift-wish-background {

    margin: 15px;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    cursor: pointer;
    /* background: rgba(255,255,255,0.7); */
    display: flex;
    align-items: center;
    justify-content: center;
}
    .gift-wish-background.outside {
        position: absolute;
        right: -10px;
        bottom: -65px;
    }
    .gift-wish-background.inside {
        position: absolute;
        right: 0px;
        bottom: 0px;
        background-color: #ffffffdb;
    }

.gift-wish-icon {
    height:75%;
    width: 75%;
}
.gift-wish-icon img{
    height:100% !important;
    width:100% !important;
}

.like-icon {
    position: absolute;
    top: 55%;
    left: 55%;
    transform: translate(-50%, -50%);
    z-index: 3;
}

.border-solid {
    border: solid;
}

#gift-qr-modal-content {
    padding: unset !important;
}

#gift-qr-modal-header {
    background: rgba(255, 179, 0, 0.734);
    min-height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
}

#gift-qr-modal-description {
    display: flex;
    align-items: center;
    justify-content: center;
}

.gift-row-slider {
    background: #5956E9;
    border-radius: 10px;
    width: 80% !important;
    margin: 10%;
    position: relative;
    margin-bottom: 0 !important;
    margin-right: 2% !important;
    margin-top: 2% !important;
    padding: 0.5em !important;
    background-repeat: no-repeat !important;
    background-position: center center !important;
    background-size: contain !important;

}

.ui.orange.button:hov {
    background-color: transparent;
    color: #5956E9;
    text-shadow: none;
    border-bottom: 2px solid #5956E9;
}

/*
.ui.basic.black.butto{
    box-shadow: 0 0 0 0px transparent !important;
    border-bottom: 2px solid #5956E9;

}*/
.ui.disabled.button {
    background: transparent;
    /*color: #5956E9!important;*/
    border-bottom: 2px solid #5956E9;
    border-radius: 0px;
}

.ui.basic.black.button {
    color: #9A9A9D !important;
    box-shadow: none !important;
}

.ui.orange.button:hover {
    background: transparent;
}

.ui-background {
    width: 100%;
    background: #E2E2E5;
    border-radius: 5px;
}

.ui-button-background {
    color: white !important;
    background: #5956E9 !important;
}

.table.gift-table {
    width: 100%;
    border-spacing: 0;
    margin-bottom: 0.5vh;
    padding-inline: 10px;
}

.search-gift {
    display: grid;
    align-items: center;
    justify-content: center;
}

.gift-tho {
    display: flex;
    width: 100%;
    gap: 5px;
}

.gift-input>.ui.input>input {
    background: #E2E2E5;
    width: 100px;
}

.gift-dropdown>.ui.fluid.dropdown {
    background: #E2E2E5;
    margin-bottom: 20px;
}

.winner-timer-gift {
    height: 25px;
    font-size: 30px;
    font-weight: bold;
    text-align: center;
    width: auto;
    margin-top: 20px;
    color: #ff0000;
    /*background-color: rgba(255,0,0,0.12);*/
    border-radius: 50%;
}

.shop-selector{
    margin:10px;
}

@media (max-width:350px) {
    .shop-selector {
        margin: 10px 0px 10px 0px !important;
    }
}