
.feedbackBlock {
    position: relative;
    padding-top: 55px;
    /*background-color: rgba(222, 222, 222, 0.8);*/
}

.send-feedback-button.disabled{
    opacity:0.5;
}

.feedback-selector {
    top: -2em;
    left: -1.3em;
    border-radius: 8px;
    /*width: 47%;*/
    text-align: center;
    padding: 10px;
    margin: 1em;
    font-weight: bold;
    font-size: middle;
    background-color: rgb(207, 207, 207);
    position: relative;
}
.feedback-selector.selected {
    background-color: rgba(91, 68, 224, 0.8);
    color: white;
}

.feedback-selectors-block {
    left: 10%;
    border-radius: 10px;
    width: 80%;
    height: 4em;
    min-width: 34vh;
    background-color: rgb(207, 207, 207);
    margin: 10px;
    padding: 20px;
    position: relative;
}

.feedback-first-selector {
    left: -2em;
    width: 43%;
    height: 3.1em;
    color: white;
}

.feedback-second-selector {
    top: -6.1em;
    left: 44%;
    width: 55%;
    height: 3.05em;
    color: white;
}

.feedback-dropdown {
    left: 5%;
    width: 90%;
    border-radius: 0px !important;
    box-shadow: 0px 30px 80px 5px rgb(216, 216, 216) !important;
    border: 1px solid rgba(227, 227, 227, 0.8);
    position:relative;
}

.feedback-textarea {
    left: 5%;
    width: 90%;
    box-shadow: 0px 30px 80px 5px rgb(216, 216, 216) !important;
    border: 1px solid rgba(220, 220, 220, 0.8);
    position: relative;
    z-index: 2;
    /*height: 150px;*/
    border-radius: 0px;
}

#create-message-button {
    left: 10%;
    border-radius: 10px;
    margin: 10px;
    padding: 20px;
    width: 80%;
    min-width: 28vh;
    box-shadow: none;
    border: 1px solid rgba(91, 68, 224, 0.8);
    position: relative;
    cursor: default;
}

#create-message-button-text {
    left: 20%;
    font-weight: bold;
    font-size: large;
    color: rgba(91, 68, 224, 0.8);
    position:relative;
}

#create-message-button-plus-border {
    left: -2em;
    border-radius: 5px;
    height: 20px;
    width: 20px;
    box-shadow: none;
    border: 2px solid rgba(91, 68, 224, 0.8);
    position:absolute;
    cursor: default;
}

#create-message-button-plus-horisontal-line {
    top: 9px;
    left: -30px;
    height: 1px;
    width: 8px;
    box-shadow: none;
    border: 1px solid rgba(91, 68, 224, 0.8);
    position:absolute;
    cursor: default;
}

#create-message-button-plus-vertical-line {
    top: 6px;
    left: -27px;
    height: 8px;
    width: 1px;
    box-shadow: none;
    border: 1px solid rgba(91, 68, 224, 0.8);
    position:absolute;
    cursor: default;
}

#feedback-image {
    left: 5% !important;
    position: relative;
}

#feedback-add-photo-button {
    left: 5%;
    border-radius: 10px;
    margin: 0px;
    padding: 10px;
    width: 15em;
    min-width: 18vh;
    box-shadow: none;
    border: 2px solid rgba(91, 68, 224, 0.8);
    background-color: rgb(229, 225, 255);
    position: relative;
    cursor: default;
}

#feedback-add-photo-button-text {
    left: 5%;
    font-weight: bold;
    font-size: large;
    color: rgba(91, 68, 224, 0.8);
    position:relative;
}

#add-photo-button-plus-border {
    top: 0.8em;
    left: 12em;
    border-radius: 5px;
    height: 22px;
    width: 22px;
    box-shadow: none;
    border: 2px solid rgba(91, 68, 224, 0.8);
    position:absolute;
    cursor: default;
}

#add-photo-button-plus-horisontal-line {
    top: 1.5em;
    right: 22px;
    height: 1px;
    width: 10px;
    box-shadow: none;
    border: 1px solid rgba(91, 68, 224, 0.8);
    position:absolute;
    cursor: default;
}

#add-photo-button-plus-vertical-line {
    top: 1.2em;
    left: 12.7em;
    height: 10px;
    width: 1px;
    box-shadow: none;
    border: 1px solid rgba(91, 68, 224, 0.8);
    position:absolute;
    cursor: default;
}

.send-feedback-button {
    left: 3%;
    border-radius: 10px;
    margin: 10px;
    padding: 17px;
    width: 90%;
    min-width: 28vh;
    box-shadow: none;
    border: 1px solid rgb(99, 71, 255);
    background-color: rgb(99, 71, 255);
    color:rgb(255, 255, 255);
    text-align: center;
    font-weight:500;
    font-size: x-large;
    position: relative;
    cursor: default;
}

#feedback-segments {
    top: 0.6em;
    bottom: 0;
    border-radius: 0;
    margin: 0;
    width: auto;
    position: relative;
}

#feedback-row {
    left: 10%;
    border-radius: 10px;
    margin: 10px;
    padding: 13px;
    width: 80%;
    min-width: 28vh;
    box-shadow: none;
    border: 2px solid rgba(35, 130, 225, 0.755);
    background: rgba(255, 255, 255, 0.7) !important;
    position: relative;
}

#feedback-row-body {
    min-height: 8vh;
    margin-left: 10vh;
}

.modal-header-text {
    left: 5%;
    font-size: x-large;
    font-weight:600;
    position: relative;
}

.modal-subject-text {
    left: 5%;
    color:rgb(95, 95, 95);
    font-size: large;
    font-weight:500;
    position: relative;
    margin-bottom: 0.5em;
}

.modal-error-text {
    z-index: 3;
    font-size: large;
    color: red;
}

.content-survey{
    display: flex;
}

.searchForm{
    margin:10px;
}


.survey-card-back-btn {
    top: 2vh;
    left: 8%;
    position: relative;
    color: #5956E9;
    font-size: large;
    font-weight: bold;
    cursor: pointer;
}
.survey-card-photo2 {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    /*height: 40vh;
    object-fit: cover;*/
    color: white;
    /*min-height: 120px;*/
    border-radius: 20px !important;
}
.survey-card-description2 {
    top: -1em;
    max-width: 100%;
    justify-content: left;
    background-color: white;
    border-radius: 20px !important;
    padding: 2.7em;
}
.survey-card-label2 {
    margin: 5px;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    letter-spacing: 0.04em;
    color: #525252;
}

.task-card-value2 {
    margin-top: 5px;
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 18px;
    font-weight: bold;
    font-size: 13px;
    line-height: 15px;
    letter-spacing: 0.04em;
    color: #343434;
}

.survey-card-description-title2 {
    padding-top: 10px;
    font-size: 1.5em !important;
    font-weight: bold;
    display: flex;
    color: #5956E9;
}