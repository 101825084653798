table.task-table {
    width: 100%;
    border-spacing: 0;
    margin-bottom: 0.5vh;
}

.search-input {
    width: 100%;
    margin-top: 1vh;
}

table.task-table > tbody > td {
    border-bottom: none;
    width: 50%;
}

.task-table-header {
    font-weight: bold;
}

.task-name {
    font-weight: bold;
}

.task-point {
    font-weight: bold;
}

.task-date {
    font-size: smaller;
    font-weight: 100;
}

.task-state {
    font-size: smaller;
    font-weight: 100;
    float: right;
}

.task-photo {
    width: 8vh;
    float: left;
    min-height: 8vh;
    border: 1px solid #f0f0f0;
    object-fit: cover;
}

.task-body {
    min-height: 10vh;
    margin-left: 10vh;
}

.taskBlock {
    position: relative;
    padding-top: 41px;
}

.task-main{
    overflow: auto;
}

.task-card-header {
    position: relative;
    display: flex;
}

.task-card-footer {
    padding-left: 1vh;
    padding-right: 1vh;
}

.task-card-photo {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 40vh;
    object-fit: cover;
    color: white;
    min-height: 120px;
}

.task-card-title {
    top: 14%;
    padding: 2vh;
    border-radius: 50px;
    box-shadow: 0 0 20px rgba(0, 0, 0, .8);
    left: 50%;
    transform: translate(-50%, -10%);
    background: rgba(0, 0, 0, .4392156862745098);
    position: absolute;
    color: white;
    font-size: 3vmax;
    line-height: 3vmax;
    text-align: center;
}

.task-back-button {
    position: absolute;
    top: 40px;
    color: white;
    font-size: x-large;
    background:radial-gradient(50% 30%, rgba(0,0,0,255),rgba(0,0,0,0));
    display: flex;
    width: 35px;
    height: 54px;
    align-items: center;
    text-align: center;
    padding-left: 4px;
    margin-left: 10px;
    padding-top: 1px;
}

.task-card-period {
    position: absolute;
    color: white;
    text-align: center;
    font-size: smaller;
    padding: 1vh;
    margin-left: 1vh;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.8);
    border-radius: 50px;
    background: #00000070;
    bottom: 2vmax;
}

.task-card-description {
    margin-inline: 20px;
    margin-top: 20px;
}

.task-card-description table {
    width: 100%;
    border-collapse: collapse;
}

.task-card-description table.task-card-info td:first-child {
    width: 70%;
}

.task-label {
    font-size: smaller;
    color: #6e6e6e;
}

.task-value {
    font-size: larger;
    font-weight: bold;
}

.task-compliteDate {
    font-size: larger;
    font-weight: bold;
}

.taskBlock .items {
    max-height: 98vh;
    overflow: auto;
}

.task-commentary {
    width: 100%;
}

.task-error-message {
    color: red;
}

.task-photo-block {
    position: relative;
    float: left;
    width: 11vh;
    margin-right: 2vh;
    margin-top: 1vh;
}

.task-photo-remove {
    position: absolute;
    top: 0;
    right: -20px;
}

.task-commentary-photo-block {
    display: flex;
}

.task-commentary-photo-block .task-photo-block .task-photo {
    width: 12vh;
    min-height: 15vh;
    float: none;
}

.submitTaskBtn{
    position: relative;
}

.task-photo-block .task-photo {
    position: relative;
    width: 8vh;
    min-height: 12vh;
    float: none;
}

.fullscreen-exit-btn {
    margin-top: 3vh;
}

.task-photo-blocks ul.images {
    display: flex;
    grid-gap: 15px;
    list-style: none;
    margin: 0;
    padding: 0;
    flex-direction: column;
}

.task-photo-blocks ul.images li {
    position: relative;
    padding-top: 66%;
}

.task-photo-blocks ul.images li img{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.rejectBtn{
    margin-top: 2px!important;
}

.titleQR{
    position: relative;
    top: 70px;
}
.qrForm{
    height: 100%;
    width: 100%;
    max-width: 400px;
}
.help-wizer{
    position: relative;
    top: 48px;
    right: -90%;
}
.help-dialog{
    padding-right: 20px;
}
.qr-heder{
    margin-top: 70px;
    font-size: 20px;
    font-weight: 900;
}
.qr-texteria .task-commentary{
    border: transparent;
}

.survey-btn{
    height: 60px;
    background-color: #5956E9 !important;
    border-radius: 10px !important;
    color: white !important;
    font-size: 17px;
}

.survey-back-btn{
    position: absolute;
    top: 40px;
    display: flex;
    width: 35px;
    height: 54px;
    align-items: center;
    padding-top: 1px;
}
.survey-heder{
    top: 16px;
    left: 8%;
    position: relative;
    color: #5956E9;
    font-size: large;
    font-weight: bold;
}

.qr-texteria .task-commentary {
    box-shadow: 0px 10px 20px 5px rgb(216, 216, 216);
    /*height: 150px;*/
    border: 1px solid #80808042;
}