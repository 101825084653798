.SelectBox
{
    width: 100%;
    height: 40px;
    margin: 0px;
    padding: 0px;
}

.avatar .photo-add-account {
    transform: translate(-50%, -50%);
    position: absolute;
    top: 95%;
    left: 50%;
    border-radius: 50%;
    width: 45px;
    height: 45px;
    background-color: #5956E9;
    color: white;
    align-content: center;
    z-index: 100;
    cursor:pointer;
}

.avatar{
    position:relative;
}

.photo-add-account i{
    text-align: center;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.photo-property-account
{
/*    top: 140px;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;*/
    border-radius: 50%;
    height: 150px;
    width: 150px;
    background-color: #fff;
    border: #767676 2px solid;
    object-fit: cover;
}


.App-Heder-Account{
    min-height: 55px;
}
.App-Heder-salute{
    /*min-height: 200px;*/
}

.greetingMsg-Account{
    margin: 10px;
    border-radius: 10px;
    margin-top: 62px;
    font-weight: 800;
    padding: 5px;
    font-size: 11px;
    background: #D3F2FF;
    line-height: 1.5;
}

.row-salute {
    display: flex;
}

.column-salute-icon {
    flex: 10%;
    text-align: center;
}
.column-salute-text {
    flex: 90%;
    text-align: left;
}

.heder-Account{
    font-weight: 600;
    margin-top: 0.6em;
    max-width: auto;
    font-size: 1em;
    line-height: 1.4285em;
    color: #868686;
}
.discription-Account {
    font-family: sans-serif;
    color: #3e3c3c;
    font-weight: bold;
    font-size: 15px;
    /*word-break: normal;*/
}
.row-discription {
    align-items: baseline;
    display: flex;
}

.column-discription .qr-code {
    display: flex;
    align-items: center;
}
.column-desc {
    width: 50vw;
    margin: 5px;
}

.qr-btn{
    margin:5px;
    cursor:pointer;
}

.qr-modal-content {
    position: relative;
    width: 320px;
    height: 320px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
}
.qr-model-close{
    position: absolute;
    top: -50px;
    right: -12px;
}
.qr-modal .close.icon {
    color: #ff6767 !important;
}

.head-acc-desc .column-desc {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.head-acc-desc {
    display: flex;
    margin: 10px;
    justify-content: center;
}

.head-acc-desc .column-desc .centered{
display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.column-desc .acc-key-header {
    margin-top:20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.acc-key-header p{
    margin:unset;
    color: #5956e9;
    font-weight: 600;
}

.column-desc .cloud-info {
    background-color: #fff;
    margin-top:3px;
    margin-bottom:3px;
    max-width: 250px;
    height: 60px;
    border-radius: 10px;
    display: flex;
    align-items: center;
}

.cloud-info h3 {
    text-align: center;
    width: 40%;
    margin: unset;
    color: #5956e9;
    word-wrap: break-word
}

.cloud-info p {
    width: 60%;
    font-size: small;
    line-height: 1.2;
    font-weight: 600;
}

.login-area .input{
    padding:7px;
    min-height:38px;
}

.login-input .icon{
    opacity:1 !important;
}

.lk-edit .main-area {
    margin-bottom:8px;
}

.red-icon {
    color: #f91515;
  }