.winner-root{
    /* position: absolute; */
    width: auto;
}

.winner-image-background {
    /* position: absolute; */
    left: 0;
    right: 0;
    margin: 0 auto;
    border-radius: 30%;
    /* border: #767676 2px solid; */
    height: 100px;
    width: 100px;
    object-fit: cover;
}

.winner-image {
    /* position: absolute; */
    left: 0;
    right: 0;
    margin: 0 auto;
    border-radius: 20%;
    height: 100px;
    width: 100px;
    object-fit: cover;
}

.winner-timer {
    /* position: relative; */
    /* left: 0; */
    /* right: 0; */
    height: 21px;
    width: 100px;
    /* object-fit: cover; */
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    color: #ff0000;
    background-color: rgba(255,0,0,0.12);
    border-radius: 50%;
}

.winner-text{
    height: 25px;
    width: 100px;
    /* object-fit: cover; */
    font-size: 15px;
    font-weight: bold;
    text-align: center;
    color: #5956e9;
    background-color: rgba(89,86,233,0.12);
    border-radius: 50%;
}
