.popupMessage{
    z-index: 999;
    border-radius: 10px;
    margin: 1%;
    padding: 13px;
    background: rgb(6, 220, 53) !important;
    position: absolute;
    /* color: black; */
    width: 98%;
    color: white;
}