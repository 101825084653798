.searchComponent{
    border-radius: 50px;
    margin: 20px;
    margin-top: 30px;
    display: flex;
}

.search-Container{
    height: 50px;
    width: 90%;
    margin: 5%;
    border-radius: 30px;
    border-color: #C9C9C9;
    border-style: solid;
    padding-left: 10px;
}
.search-child.input.ui.input>input{
    border: transparent !important;
    background: transparent !important;
}
.search-icon .button{ 
    background: transparent !important;
    border-left: 3px solid #C9C9C9 !important;
}

.ui.menu{
    background: transparent;
}