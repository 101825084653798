.select-bar {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    background-color: #d9d6d6;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 10px;
    flex-direction: row;
    justify-content: space-around;
    align-items: stretch;
    
}

    .select-bar > .select-bar-elem {
        padding: 10px 0px 10px 0px;
        border-radius: 10px;
        color: black;
        font-weight: bold;
        text-align: center;
        min-width: 32%;
        max-width: 40%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        /*border: 1px solid #0000000f;*/
        cursor:pointer;
        position: relative;
    }

        .select-bar > .select-bar-elem.bar-elem-selected {
            background-color: #7461e0;
            color: white;
        }

.counter{
    position: absolute;
    right: -15px;
    top: -10px;
}