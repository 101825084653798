.qr-scanner-component .scanner {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.qr-scanner-component .scanner .scanner-err{
    color:red;
}
.qr-scanner-component .scanner .qr-scanner-window{
    margin:10px;
}

.qr-scanner-component .scanner .help-icon-container {
    width: 280px;
    display: flex;
    justify-content: space-between;
}
.qr-scanner-component .scanner .help-icon-container i {
    /*margin-right:20px;*/
    margin-bottom:10px;
    cursor:pointer;
}

.qr-scanner-component .close.icon {
    color: #ff6767 !important;
}