.start-screen-background {
    height: 100%;
    width: 100%;
    background-color: rgb(97, 90, 235);
    background-size: cover;
    background-position-y: bottom;
    position: fixed;
}

.start-screen-header {
    flex-direction: column;
    align-items: bottom;
}

.start-screen-header-text1 {
    top: 0em;
    line-height: 1.2;
    font-size: 1.5em !important;
    font-weight: bold;
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: stretch;
    color: white;
    margin-inline: 8%;
}

.start-screen-header-text2 {
    top: 1em;
    font-size: 1.5em !important;
    font-weight: bold;
    text-align: justify;
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: stretch;
    color: white;
    margin-left: -3.4em;
}

.start-screen-header-text3 {
    top: 2em;
    font-size: 1.5em !important;
    font-weight: bold;
    text-align: justify;
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: stretch;
    color: white;
    margin-left: -3.7em;
}

#start-screen-login-button {
    top: 2.2em;
    left: 10%;
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: stretch;
    width: 80% !important;
    padding: 0;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    margin-bottom: 0.6rem;
    border: 1px solid rgba(255, 255, 255, 0.76) !important;
    border-radius: 8px !important;
}

#start-screen-reg-button {
    top: 2.2em;
    left: 10%;
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: stretch;
    width: 80% !important;
    padding: 0;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    margin-bottom: 0.6rem;
    border: 1px solid rgba(255, 255, 255, 0.76) !important;
    border-radius: 8px !important;
    color: rgb(97, 90, 235);
    background-color: rgb(222, 221, 243);
}

#start-screen-detail-text {
    top: 2em;
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: stretch;
    font-size: 0.9em !important;
    font-weight: bold;
    color: rgb(241, 240, 254);
}

.start-screen-footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 1rem;
}

.start-screen-footer a {
    color: rgb(241, 240, 254);
}