
.photo-property
{   position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    border-radius: 50%;
    height: 100px;
    width: 100px;
    background-color: #fff;
    border: #767676 2px solid;
    object-fit: cover;
    margin-top: -55px;
   
}

.winner
{
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    height: 100px;
    width: 100px;
    /* border: #910000 2px solid; */
    object-fit: cover;
    margin-top: -75px;
}

.info-Name
{
    color: rgb(4 4 4);
    font-weight: 900;
    font-size: 20px;
    margin-top: -40px;
    margin-bottom: 10px;
}

.heder-App{
    margin-top: 100px;
}
.heder-main{
    border-radius: 10px;
    padding: 15px;
    background: white;
    margin: 20px;
}
.discription-main{
    text-align: center;
    padding-top: 95px;
}
.heder-text{
    color: #868686
}
.description-text{
    font-weight: bold;
    color: #010101;
}

@media (max-width: 416px) 
{
   
    .info-Name-left { 
        left: 20vh;
        height: 100px;  
    }
}

.simple-nav-bar {
    display: flex;
    justify-content: space-between;
}

.simple-nav-bar .right-nav-elems {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
}

.simple-nav-bar .left-nav-elems{
    display:flex;
}

.simple-nav-bar .nav-elem {
    margin: 5px;
    display:flex;
    align-items: center;
    font-weight:700;
    font-size:12px;
    cursor:pointer;
    color:#444649;

}

@media (max-width:350px) {
    .simple-nav-bar .nav-elem p {
        display: none;
    }

    .simple-nav-bar .nav-elem img {
        width: 35px !important;
        margin-right: 10px !important;
    }
}

.simple-nav-bar .nav-elem img {
    width:20px;
    margin-right:3px;

}
.ui.menu.fixed{
    width:unset !important;
}

.unread-home {
    border-radius: 40px;
    width: 20px;
    color: white;
    background-color: #10d510;
    text-align: center;
}