.rule-block {
    border: 1px solid #888;
    padding: 1vh;
    text-align: left;
    font-size: calc(6px + 2vmin);
    white-space: pre-wrap;
}

#registration-page-register-button {
    top: 3em;
    left: 0%!important;
    font-size: 1em !important;
    position: relative;
    justify-content: center;
    align-items: stretch;
    width: 100%!important;
    padding: 0;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    margin-bottom: 0.6rem;
    border-radius: 8px !important;
}

#registration-page-back-to-main-button {
    top: 3em;
    left: 0%;
    font-size: 1em !important;
    position: relative;
    justify-content: center;
    align-items: stretch;
    width: 100%!important;
    padding: 0;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    margin-bottom: 0.6rem;
    border-radius: 8px !important;
    color: rgb(97, 90, 235);
    background-color: rgb(225, 223, 255);
    box-shadow: 10px 40px 40px 5px rgb(236, 236, 236) !important;
}