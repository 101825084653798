
.MainPosition {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: auto;
    font-weight: bold;
}

.ui.labeled.icon.menu .item.menu-item {
    text-align: left;
    padding-right: 0em;
    height: 42px;
}

.ui.labeled.icon.menu > .item.menu-item > i.icon {
    display: inline-block;
    font-size: 1.3em !important;
    margin: 0 !important;
}

.ui.labeled.icon.menu > .item.header-item {
    height: 8em;
    background: rgb(255, 255, 255);
} 

#header-item-main {
    top: 0px;
    height: 150px;
    background: rgb(255, 255, 255);
    position: relative;
} 

.header-item-text {
    top: 0vh;
    
    color: rgb(0, 0, 0);
    font-weight: bold;
    font-size: 1.4em !important;
} 
.menu-item-text {
    top: 3vh;
    color: rgb(255, 255, 255);
    font-size: 1.1em !important;
}
.menu-slider {
    min-width: 280px;
    border-radius: 20px !important;
    box-shadow: inset 0px 0px 500px 500px rgb(104, 79, 247) !important;
    background: rgba(171, 171, 171, 0.7) !important;
    padding: 10px !important;
}

#menu-slider2 {
    min-width: 100%;
    height: 100%!important;
    box-shadow: inset 0px 0px 500px 500px rgb(255, 255, 255) !important;
    background: rgba(171, 171, 171, 0.7) !important;
    padding: 0px !important;
    overflow-y: scroll !important;
}
.menu-pushable .pushable{
    width: 100% !important;
}
#menu-header-item2 {
    /*min-width: 22em;*/
    top: 8vh;
    color: rgb(0, 0, 0);
    font-size: 1.7em !important;
    border-bottom: 2px solid rgb(255, 255, 255) !important;
}

#menu-header-text2 {
    top: 3.5em;
    left: 1em;
    position: absolute;
    color: rgb(0, 0, 0);
    font-size: 2.1em !important;
    font-weight: bold;
}

#menu-header-line21 {
    top: 58px;
    left: 2.3em;
    height: 2px;
    width: 20px;
    position: absolute;
    box-shadow: inset 0px 0px 500px 500px rgb(120, 120, 120) !important;
}

#menu-header-line22 {
    top: 64px;
    left: 2.3em;
    height: 2px;
    width: 13px;
    position: absolute;
    box-shadow: inset 0px 0px 500px 500px rgb(120, 120, 120) !important;
}

#menu-header-line23 {
    top: 70px;
    left: 2.3em;
    height: 2px;
    width: 20px;
    position: absolute;
    box-shadow: inset 0px 0px 500px 500px rgb(120, 120, 120) !important;
}

#menu-header-sphere21 {
    top: -50px;
    right: 45px;
    height: 110px;
    width: 110px;
    border-radius: 50%;
    position: absolute;
    box-shadow: inset 0px 0px 500px 500px rgb(134, 128, 243) !important;
    display: inline-block;

}

#menu-header-sphere22 {
    top: 90px;
    right: 30px;
    height: 21px;
    width: 21px;
    border-radius: 50%;
    position: absolute;
    box-shadow: inset 0px 0px 1px 5px rgb(155, 151, 244) !important;
    display: inline-block;
}

#menu-start-item2 {
    min-width: 18em;
    border-top-left-radius: 20px !important;
    border-top-right-radius: 20px !important;
    border-bottom-right-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    box-shadow: inset 0px 0px 500px 500px rgb(91, 84, 239) !important;
    border-bottom: 1px solid rgb(102, 95, 238) !important;
}

#menu-middle-item2 {
    min-width: 18em;
    text-align: left;
    padding-right: 0em;
    height: 44px;
    border-radius: 0px !important;
    box-shadow: inset 0px 0px 500px 500px rgb(91, 84, 239) !important;
    border-bottom: 1px solid rgb(102, 95, 238) !important;
}

#menu-middle-item-low-hr2 {
    top: 42px;
    left: 60px;
    height: 1px;
    width: 220px;
    border: 0.5px solid rgba(125, 119, 236, 0.592);
    position: absolute;
}

#menu-middle-icon2 {
    top: 0.9em;
    left: 1.6em;
    height: 20px;
    position: absolute;
}

#menu-middle-text2 {
    top: 0.8em;
    left: 4em;
    position: absolute;
    color: rgb(255, 255, 255);
    font-size: 1.1em !important;
}

#menu-end-item2 {
    
    position: relative;
    height: 300px;
    min-width: 18em;
    min-height: 3em;
    /* max-height: 200px; */
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
    /* border-bottom-right-radius: 20px !important; */
    /* border-bottom-left-radius: 20px !important; */
    box-shadow: inset 0px 0px 500px 500px rgb(91 84 239) !important;
}

#menu-header-sphere23 {
    position: absolute !important;
    top: 30px;
    right: 70px;
    height: 80px;
    width: 80px;
    border-radius: 50%;
    position: relative;
    box-shadow: inset 0px 0px 500px 500px rgb(116 109 245) !important;
    display: inline-block;
}

#menu-header-sphere24 {
    position: absolute;
    top: 100px;
    right: 190px;
    height: 21px;
    width: 21px;
    border-radius: 50%;
    /* position: relative; */
    box-shadow: inset 0px 0px 1px 5px rgb(122 116 248) !important;
    display: inline-block;
}

.header-item-close-icon {
    top: 0.6em;
    right: 2vh;
    position: absolute;
}
.NavBar
{
    display: flex;
    align-items:center;
}
.NavBarItem
{
    font-size: 11px;
    margin: 5px;
}
.NavBarImg
{
    width: 17px;
    height: 17px;
}
a {
    color: #444649;
}

.ui.menu .item:before{
    background: none;
}

.ui.menu{
    border: none !important;
    box-shadow: none !important;
}

.rightName{
    right: 10%;
    top: 15px;
    font-weight: 800;
    font-size: 17px;
    position: absolute;
    
}

.textNavBar{
    line-height: 1.5;
}

.ui.visible.left.overlay.sidebar{
    overflow-y: hidden !important;
    
}
.visibleslider{
  position: fixed;
  display: flex;
  width: 100%;
}
.hiddenslider{
    position: relative;
    display:block;
}

.notification {
    position: relative;
    top: -10px;
    right: 5px;
}
