table.task-table {
    width: 100%;
    border-spacing: 0;
    margin-bottom: 0.5vh;
}

.search-input {
    width: 100%;
    margin-top: 1vh;
}

table.task-table > tbody > td {
    border-bottom: none;
    width: 50%;
}

.task-table-header {
    font-weight: bold;
}

.task-name {
    font-weight: bold;
}

.task-point {
    font-weight: bold;
}

.task-date {
    font-size: smaller;
    font-weight: 100;
}

.task-state {
    font-size: smaller;
    font-weight: 100;
    float: right;
}

.task-photo {
    width: 8vh;
    float: left;
    min-height: 8vh;
    border: 1px solid #f0f0f0;
    object-fit: cover;
}

.task-body {
    min-height: 10vh;
    margin-left: 10vh;
}

.taskBlock {
    position: relative;
    padding-top: 41px;
}

.task-main{
    overflow: auto;
}

.task-card-header {
    position: relative;
    display: flex;
}

.task-card-footer {
    padding-left: 1vh;
    padding-right: 1vh;
}

.task-card-photo {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 40vh;
    object-fit: cover;
    color: white;
    min-height: 120px;
}

.task-card-title {
    top: 14%;
    padding: 2vh;
    border-radius: 50px;
    box-shadow: 0 0 20px rgba(0, 0, 0, .8);
    left: 50%;
    transform: translate(-50%, -10%);
    background: rgba(0, 0, 0, .4392156862745098);
    position: absolute;
    color: white;
    font-size: 3vmax;
    line-height: 3vmax;
    text-align: center;
}

.task-back-button {
    position: absolute;
    top: 40px;
    color: white;
    font-size: x-large;
    background:radial-gradient(50% 30%, rgba(0,0,0,255),rgba(0,0,0,0));
    display: flex;
    width: 35px;
    height: 54px;
    align-items: center;
    text-align: center;
    padding-left: 4px;
    margin-left: 10px;
    padding-top: 1px;
}

.task-card-period {
    position: absolute;
    color: white;
    text-align: center;
    font-size: smaller;
    padding: 1vh;
    margin-left: 1vh;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.8);
    border-radius: 50px;
    background: #00000070;
    bottom: 2vmax;
}

.task-card-description {
    height: 57vh;
}

.task-card-description table {
    width: 100%;
    border-collapse: collapse;
}

.task-card-description table.task-card-info td:first-child {
    width: 70%;
}

.task-label {
    font-size: smaller;
    color: #6e6e6e;
}

.task-value {
    font-size: larger;
    font-weight: bold;
}

.task-compliteDate {
    font-size: larger;
    font-weight: bold;
}

.taskBlock .items {
    max-height: 98vh;
    overflow: auto;
}

.task-commentary {
    width: 100%;
}

.task-error-message {
    color: red;
}

.task-photo-block {
    position: relative;
    float: left;
    width: 11vh;
    margin-right: 2vh;
    margin-top: 1vh;
}

.task-photo-remove {
    position: absolute;
    top: 0;
    right: 0;
}

.task-commentary-photo-block {
    display: flex;
}

.task-commentary-photo-block .task-photo-block .task-photo {
    width: 12vh;
    min-height: 15vh;
    float: none;
}

.submitTaskBtn{
    position: relative;
}

.task-photo-block .task-photo {
    position: relative;
    width: 8vh;
    min-height: 12vh;
    float: none;
}

.fullscreen-exit-btn {
    margin-top: 3vh;
}

.task-photo-blocks ul.images {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(12vh, 1fr));
    grid-gap: 15px;
    list-style: none;
    margin: 0;
    padding: 0;
}

.task-photo-blocks ul.images li {
    position: relative;
    padding-top: 66%;
}

.task-photo-blocks ul.images li img{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.rejectBtn{
    margin-top: 2px!important;
}

.inputEdit{
    width: 95%;
}
.iconOffer{
    padding-left: 5px;
    line-height: 0.6 !IMPORTANT;
}
.task-tho{
    display:flex;
    width: 100%;
    gap:5px;
    flex-wrap: wrap;
}

.task-tho>* {
    flex: 1 1 160px;
}
.task-checbox .ui.checkbox {
    padding: 3px;

}
.task-dropdown> .ui.fluid.dropdown{
    background: #E2E2E5;
    margin-bottom: 20px;
}
.search-input-btn .ui-button-search{
    height: 60px; 
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
}
.search-input-btn{
    margin:10px;
}

.ui-container {
    margin-top: 10px !important;
    border-radius: 15px !important;
}

.ui-image{
    border-radius: 15px;
}

.task-row-point{
    color: #5956E9;
}

.row-salute {
    border-radius: 10px;
    background: #D3F2FF;
    display: flex;
    padding: 5px;
    align-items: center;
}

.column-salute-icon {
    flex: 10%;
    text-align: center;
}
.column-salute-text {
    flex: 90%;
    text-align: left;
    padding-left: 12px;
    font-weight: bold;
}

.ui-button-search{
    background-color: #5956E9;
}





.task-card-period2 {
    top: -2.2em;
    position: relative;
    color: rgb(57, 57, 57);
    left: 0vw;
    font-size: smaller;
    text-align: right;
    margin-right: 10%;
    /*padding: 1vh;
    margin-left: 1vh;
    bottom: 2vmax;*/
}

.task-card-photo2 {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    /*height: 40vh;
    object-fit: cover;*/
    color: white;
    /*min-height: 120px;*/
    border-radius: 20px !important;
}

.task-card-description2 {
    top: -1em;
    max-width: 100%;
    height: 100vh;     /* чтобы растянуть вниз до конца экрана */
    overflow: auto;
    justify-content: left;
    background-color: white;
    border-radius: 20px !important;
    padding: 2.7em;
}
.offertask-card-description2{
    top: -1em;
    max-width: 100%;
    overflow: auto;
    justify-content: left;
    background-color: white;
    border-radius: 20px !important;
    padding: 2.7em;
}
.task-card-description-title2 {
    top: -1em;
    left: 5%!important;
    font-size: 1.5em !important;
    font-weight: bold;
    position: relative;
    display: flex;
    color: #5956E9;
}

.task-card-label2 {
    margin: 5px;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    letter-spacing: 0.04em;
    color: #525252;
}

.task-card-modal-label2 {
    margin-bottom: 15px;
    font-style: normal;
    font-size: 13px;
    line-height: 15px;
    letter-spacing: 0.04em;
    color: #ffffff;
}

.task-card-modal-label-right{
    margin-top: 15px;
    margin-bottom: 8px;
    margin-right: 1%;
    font-style: small;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.1em;
    color: #ffffff;
    text-align: right;
}

.task-card-modal-rating-right{
    margin-bottom: 8px;
    text-align: right;
}

.task-card-value2 {
    margin-top: 5px;
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 18px;
    font-weight: bold;
    font-size: 13px;
    line-height: 15px;
    letter-spacing: 0.04em;
    color: #343434;
}

#task-card-action-button {
    top: 1em;
    left: 0%!important;
    font-size: 1.2em !important;
    font-weight: 550;
    position: relative;
    justify-content: center;
    align-items: stretch;
    width: 100%!important;
    padding: 0;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    margin-bottom: 0.6rem;
    border-radius: 8px !important;
    box-shadow: 10px 30px 30px 5px rgb(236, 236, 236) !important;
}

#task-card-modal-window {
    border-radius: 15px !important;
}

.task-card-modal-backgroud {
    background-color: #5956E9 !important;
    border-radius: 15px !important;
}

#task-card-modal-form {
    margin: 20px !important;
}

.task-card-back-btn {
    top: 2vh;
    left: 8%;
    position: relative;
    color: #5956E9;
    font-size: large;
    font-weight: bold;
    cursor: pointer;
}

.task-card-modal-back-btn {
    top: -1em;
    position: relative;
    color: #ffffff;
    font-size: medium;
    margin-bottom: 20px;
    cursor: pointer;
}

#task-card-modal-action-button {
    top: 1em;
    left: 0%!important;
    font-size: 1.2em !important;
    font-weight: 550;
    position: relative;
    justify-content: center!important;
    align-items: stretch;
    width: 100%!important;
    padding: 0;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    margin-bottom: 0.6rem;
    border-radius: 8px !important;
    border: 1px solid rgba(255, 255, 255, 0.919) !important;
}

#task-card-get-info-button {
    top: 1em;
    left: 0%!important;
    font-size: 1.2em !important;
    font-weight: 700;
    position: relative;
    justify-content: center;
    align-items: stretch;
    width: 100%!important;
    padding: 15px;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    margin-bottom: 3rem;
    border-radius: 8px !important;
}

#task-card-notification-text {
    top: 0em;
    left: 1em !important;
    font-size: 0.85em !important;
    font-weight: bold;
    justify-content: left !important;
    position: relative !important;
    display: flex !important;
    padding-left: 40px !important;
    padding-top: 8px;
    padding-right: 8px;
    padding-bottom: 8px;
    min-width: 200px !important;
    width: 70vw !important;
    color: rgba(0, 0, 0, 0.67);
    border-radius: 10px !important;
    border: none !important;
    box-shadow: 0 0 0 500px #D3F2FF inset !important;
}

#task-card-notification-icon {
    top: 6px;
    left: 10px !important;
    display: flex;
    position: absolute;
}

.task-photo-block2 {
    top: 5px;
    position: relative;
    float: right;
    width: 25vh;
    margin-top: 1vh;
}

.description .task-commentary{
    /*min-height:150px;*/
}

.file-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}