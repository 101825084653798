.notificationBlock {
    padding-top: 41px;
    padding-left: 1vh;
    padding-right: 1vh;
    height: 100vh;
    overflow: auto;
}

.notification-body{
    min-height: 10vh;
    margin-left: 10vh;
}

.notification-name{
    font-weight: bold;
}

.notification-photo{
    float: left;
    font-size: 7vh;
    margin-top: 2vh;
}

.notification-label{
    font-size: smaller;
    color: #6e6e6e;
    margin-top: 2vh;
}

.notification-commentary{
    width: 100%;
}

.notification-error-message{
    color: red;
}

.notification-card-description {
    margin-top: 2vh;
    height: calc(92vh - 42px);
    overflow: auto;
}

.notification-card-title{
    font-size: larger;
    font-weight: bold;
}
.notification-back-button{
    font-size: 4vh;
    margin-top: 1vh;
}

.notification-row-label{
    font-size: smaller;
    color: #6e6e6e;
}

.notification-card.ui.segment{
    margin-top: 1vh;
}