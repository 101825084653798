.photo {
    border-radius: 50%;
    height: 18vh;
    width: 18vh;
    background-color: #fff;
    border: #767676 2px solid;
    object-fit: cover;
    margin-bottom: 5vh;
}

.photo-area {
    position: relative;
}

.photo-add-button {
    position: absolute;
    bottom: 5vh;
    right: 0;
    border-radius: 50%;
    width: 5vh;
    height: 5vh;
    background-color: #f2711c;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    display: flex;
    z-index: 100;
}

.photo-add-button > i.icon {
    margin: 0;
}

.app-welcome-text{
    position:absolute;
    top:75%;
    left:50%;
    transform:translate(-50%, 0);
    text-align:center;
}

.App-header {
    min-height: 50vh;
    flex-direction: column;
    align-items: bottom;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    background-color: #f7f7f7;
    background-image: url('../images/Background-main.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position-y: bottom;
}
.App-welcome-header {
    font-size: 35px !important;
    font-weight: bold;
    color: white;
    white-space: nowrap;
}
.App-welcome-name {
    font-size: 22px !important;
    font-weight: bold;
    color: white;
}

.App-welcome-male {
    min-height: 100vh;
    justify-content: center;
    color: white;
    background-image: url('../images/welcome-screen-male.png');
    background-repeat: no-repeat;
    background-position-x:center;
    background-color: #5753fd;
    position:relative;
}
.App-welcome-female {
    min-height: 100vh;
    justify-content: center;
    color: white;
    background-image: url('../images/welcome-screen-female.png');
    background-repeat: no-repeat;
    background-position-x:center;
    background-color: #5753fd;
    position:relative;
}





.event-Heder
{
    position: absolute;
    height: 10vmin;
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: rgb(96 92 92);
    font-weight: bold;
    margin-left: 5vh;
    font-size: calc(10px + 2vmin);
}

/*.info {
    position: absolute;
    height: 20vmin;
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: rgb(6, 6, 6);
    font-weight: bold;
}*/

.info > .info-detail {
    font-size: calc(8px + 1.25vmin);
    font-weight: normal;
    text-align: center;
    margin-top: 1vh;
    margin-bottom: 1vh;
    color: #767676;
}

.info-top-left {
    top: 5vh;
    left: 5vh;
}

.info-bottom-left {
    left: 0;
    bottom: 0;
}

.info-top-right {
    right: 0;
    top: 12vh;
    width: 15vmax;
}

.info-bottom-right {
    bottom: 0;
    right: 0;
}

.info-bottom {
    bottom: 0;
    color: #767676;
}

.ui.items > .item > .content.content-format, .content-format {
    margin-left: 2vh;
    margin-right: 2vh;
}

.item {
    margin: 0 !important;
}

.ui.items {
    margin-top: 5px;
}

.item.checkbox-block{
    margin-top: 8px!important;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-body {
    min-height: 30vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-body>.ui.container.grid {
    width: 90% !important;
}

.app-registration-body {
    min-height: 70vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: #767676!important;
    width: 90%;
    margin-right: auto;
    margin-left: auto;
    align-content: center;
}

.div-space {
    height: 4vh;
}

.App-link {
    color: #61dafb;
}

.login-input {
    width: 100%;
    margin-bottom: 1vh;
}

.password-confirm{
    margin-top: 1vh;
}

.error-class {
    color: red;
    text-align: left;
}

.error-class.small {
    font-size: smaller;
}

.App {
    text-align: center;
}

.App-link {
    color: #61dafb;
}

.indicator{
    font-size: 2vmax;
    display: inline-block;
}

.danger{
    margin: 5px;
    padding: 1px;
    color: #a94442;
    background-image: linear-gradient(to bottom,#f2dede 0,#e7c3c3 100%);
    background-repeat: repeat-x;
    border-color: #dca7a7;
    border: solid 1px;
}

.success{
    margin: 5px;
    padding: 1px;
    color: #3c763d;
    background-image: linear-gradient(to bottom,#dff0d8 0,#c8e5bc 100%);
    background-repeat: repeat-x;
    border-color: #b2dba1;
    border: solid 1px;
}

.moreDetails{
    color: orange;
    text-align: center;
    width: 100%;
}

.greetingMsg{
    margin-top: 42px;
    font-weight: bold;
    text-align: center;
    border: solid 1px;
}

#welcome-screen-modal-window {
    margin: -1em !important;
    width: 100%;
    height: 100%;
    /*border-radius: 15px !important;*/
    overflow-y:hidden;
    overflow-x:hidden;
}

#welcome-screen-modal-backgroud {
    margin: 0px !important;
    padding: 0px !important;
    background-color: #5956E9 !important;
    border-radius: 15px !important;
}

#welcome-screen-modal-description {
    margin: 0px !important;
    padding: 0px !important;
}

#welcome-screen-modal-form {
    margin: 0px !important;
}

.rule-modal {
    flex-direction: column !important;
}

.rule-block {
    border: 1px solid #888;
    padding: 3vh !important;
    text-align: left;
    font-size: calc(6px + 2vmin);
    white-space: pre-wrap;
    width: 100%;
    height: 100%;
}

.rule-header {
    text-align: center;
    font-size: calc(6px + 2vmin);
    white-space: pre-wrap;
}

.rule-actions {
    text-align: center !important;
}

.rule-content {
    padding: 10px !important;
    flex-direction: column !important;
}
.rule-description {
    margin: 0px !important;
    /*padding: 0px !important;*/
    padding: 11px !important;
}

.new-user-notification {
    margin: 10px;
    border-radius: 10px;
    margin-top: -50px;
    font-weight: 100;
    padding: 5px;
    font-size: 12px;
    background: #D3F2FF;
    line-height: 1.5;
    display: flex;
    flex-direction: row;
}