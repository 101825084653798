.approvingBlock {
    margin-top: 50px;
    margin-left: 10px;
    margin-right: 10px;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.approvingBlock .search-bar{
    /*width:100%;*/
    margin-bottom: 20px;
}
.approvingBlock .campers{
    width:100%;
}
