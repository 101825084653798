/*.App {
    font-family: sans-serif;
    text-align: center;
}*/

.main-area {
    position:relative;
    display: flex;
    align-items: center;
    background-color: white;
    text-align: left;
    border-radius:5px;
}

.main-area .area {
    overflow-y: scroll;
    width:100%;
    height:100%;
    overflow-x:hidden;
    padding:7px;
    position:relative;

}

.main-area .area * {
    /*height:100%;*/
    width:100%;
}

.main-area .input {
    position:relative;
    overflow-y: scroll;
    width: 100%;
    height:38px;
    overflow-x:scroll;
    padding:7px;    
}

.main-area .input * {
    width:100%;
    /*height:100%;*/
}

.area .DraftEditor-root{
    height:100%;
}

.DraftEditor-root .DraftEditor-editorContainer{
    height:100%;
}

.DraftEditor-editorContainer > div {
    height: 100%;
}

.main-area .area::-webkit-scrollbar {
    display: none;
}
.main-area .input::-webkit-scrollbar {
    display: none;
}

.inner-icon{
    padding-left:10px !important;
    padding-right:10px !important;
    height:100% !important;
}

.red-highlight {
    background-color: #ff00005e;
    border-radius:4px;
    /*padding: 2px;*/
}

.main-area .opacity-input::-webkit-scrollbar {
    display: none;
}

.main-area .opacity-input {
    margin-right: 1px;
    word-break: break-word;
    background-color: transparent;
    border: none;
    resize: none;
    width: inherit !important;
    height: inherit !important;
    z-index: 9;
    /*opacity: 0;*/
    position: absolute;
    left: 0;
    top: 0;
    padding: 7px;
    font: inherit;
    outline:none;
}
.main-area .opacity-input {
}