.userManual-block{
    padding-top: 70px;
    padding-left: 1vh;
    padding-right: 1vh;
    /*height: 100vh;*/
    /*overflow: auto;*/
}

.manual-btn{
    margin-top:10px !important;
}

.manual-back-button{
    font-size: 4vh;
    margin-top: 1.5vh;
}

.react-pdf__Page__canvas{
    /*width:100% !important;*/
}

.description .document {
    display: flex;
    justify-content: stretch;
}

.document img{
    width:108%;
}

.header .pager {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
}

.pager-setter{
    cursor:pointer;
}