.camper-row {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 3px 6px 2px #00000038;
    margin-top: 10px;
    margin-bottom: 10px;
    overflow: hidden;
}

.camper-row .camper-input-code{
    width: 100%;
    padding:10px;
}
.camper-row .camper-link-acc{
    padding-left:10px;
    display:flex;
    align-items: center;
}
.camper-row .camper-link-acc > i{
    cursor:pointer;
}

.camper-row .reg-acc-btn{
    width:100%;
    margin-top:5px;
}

.camper-row .camper-input-code > input{
    border-radius:0px;
    flex-shrink: 1;
    min-width: 10px;
}

.camper-row .camper-input-code > button{
    border: 1px solid #e0e1e2;
}

.camper-row .camper-input-code .qr-btn{
    border-radius: 5px 0 0 5px;
}

.camper-row > .camper-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 5px;
    padding-bottom: 5px;
    margin:5px;
    cursor:pointer;
}

.camper-row > .camper-header > .camper-sub-header > h4{
    margin:0px 0px 0px 0px !important;
    font-weight:100;
}

.camper-row > .camper-header > .camper-sub-header > i{
    opacity: 0.75 !important;
}

.camper-sub-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
    margin-right: 10px !important;
}

.camper-row .camper-approve-error{
    color: red;
    text-align:center;
    font-weight:600;
}