.search-bar > input {
    border-radius: 25px !important;
    background-color: #f3f3f3 !important;
    border: 2px solid rgba(42, 42, 42, 0.292) !important;
}

.search-bar > .ui.icon.button {
    border-radius: 0 25px 25px 0 !important;
    background-color: #f3f3f3 !important;
    border: 2px solid rgba(42, 42, 42, 0.292) !important;
}

.search-bar {
    margin-top: 1em;
    margin-bottom: 1em;
    width: 100%;
}
