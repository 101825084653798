
.login-screen-background {
    background-color: rgb(97, 90, 235);
    height: 100%;
    margin: 0px;
}

.login-screen-header {
    height: 250px;
    background-color: rgb(94, 86, 238);
}

.restore-password-screen-header {
    height: 250px;
    background-color: rgb(94, 86, 238);
    background-image: url('../../images/restore-password-background.png');
    background-repeat: no-repeat;
    background-position-x:center;
}

.login-screen-header-logo {
    top: 1em;
    position: relative;
    display: flex;
    margin-left: 3.5em;
}

.login-screen-header-text1 {
    top: 0.5em;
    font-size: 2.5em !important;
    font-weight: bold;
    position: relative;
    display: flex;
    color: white;
    margin-left: 1.5em;
}

.login-screen-header-text2 {
    top: 1em;
    font-size: 2.5em !important;
    font-weight: bold;
    position: relative;
    display: flex;
    color: white;
    margin-left: 1.45em;
}

.login-screen-body {
    max-width: 100%;
    /*height: 800px !important;*/
    /*min-height: 100% !important;*/
    height: 100vh;     /* чтобы растянуть вниз до конца экрана */
    overflow: auto;
    justify-content: left;
    background-color: white;
    border-radius: 20px !important;
    padding: 2.7em;
}

.login-screen-body-text1 {
    top: 0em;
    left: 5%!important;
    font-size: 1.2em !important;
    font-weight: bold;
    position: relative;
    display: flex;
    color: rgb(0, 0, 0);
}

#login-icon {
    top: 1em !important;
    margin-left: 5% !important;
    /*opacity: 35%;*/
    display: flex;
    position: relative;
}

.login-screen-body-text2 {
    top: -0.5em;
    margin-left: 15% !important;
    font-size: 1em !important;
    font-weight: bold;
    position: relative;
    display: flex;
    color: rgba(0, 0, 0, 0.461);
}

#login-screen-email {
    margin-left: 5%!important;
    max-width: 90%!important;
    border-left: none !important;
    border-top: none !important;
    border-right: none !important;
    border-bottom: 1px solid rgba(34, 36, 38, 0.44) !important;
    box-shadow: 0 0 0 30px white inset !important;
    border-radius: 0 !important;
    padding-left: 0 !important;
    padding-bottom: 0.7em !important;
    font-size: 1.2em !important;
    font-weight: bold;
}

#login-password-icon {
    top: 1.5em;
    margin-left: 5% !important;
    /*opacity: 35%;*/
    display: flex;
    position: relative;
}

.login-screen-body-text3 {
    top: 0em;
    margin-left: 15% !important;
    font-size: 1em !important;
    font-weight: bold;
    position: relative;
    display: flex;
    color: rgba(0, 0, 0, 0.461);
}

#login-screen-password {
    margin-left: 5%!important;
    max-width: 90%!important;
    border-left: none !important;
    border-top: none !important;
    border-right: none !important;
    border-bottom: 1px solid rgba(34, 36, 38, 0.44) !important;
    box-shadow: 0 0 0 30px white inset !important;
    border-radius: 0 !important;
    padding-left: 0 !important;
    padding-bottom: 0.7em !important;
    font-size: 1.2em !important;
    font-weight: bold;
}

#login-password-show-icon {
    top: 35em;
    left: calc(95% - 4.5em) !important;
    display: flex;
    position: absolute;
}

.login-screen-body-text4 {
    top: 1em;
    left: 5%!important;
    font-size: 1em !important;
    font-weight: bold;
    position: relative;
    display: flex;
    color: rgba(26, 45, 255, 0.738);
}

.login-screen-error-text5 {
    top: 2.5em;
    left: 10%!important;
    font-size: 0.85em !important;
    font-weight: bold;
    justify-content: right;
    position: relative;
    display: flex;
    padding: 8px;
    max-width: 250px !important;
    color: rgba(0, 0, 0, 0.67);
    border-radius: 10px !important;
    border: none !important;
    box-shadow: 0 0 0 30px #D3F2FF inset !important;
}

.login-screen-error-notification-icon {
    top: 0em;
    left: calc(5px + 10%) !important;
    display: flex;
    position: relative;
}

.restore-password-notification-text {
    top: 2.5em;
    left: 0%!important;
    font-size: 0.85em !important;
    font-weight: bold;
    justify-content: right;
    position: relative;
    display: flex;
    padding: 8px;
    min-width: 250px !important;
    color: rgba(0, 0, 0, 0.67);
    border-radius: 10px !important;
    border: none !important;
    box-shadow: 0 0 0 30px #D3F2FF inset !important;
}

.restore-password-notification-icon {
    top: 0em;
    left: calc(4%) !important;
    display: flex;
    position: relative;
}

#login-screen-login-button {
    top: 3em;
    left: 0%!important;
    font-size: 1.2em !important;
    position: relative;
    justify-content: center;
    align-items: stretch;
    width: 100%!important;
    padding: 0;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    margin-bottom: 0.6rem;
    border-radius: 8px !important;
}

#login-screen-to-main-button {
    top: 3em;
    left: 0%;
    font-size: 1.2em !important;
    position: relative;
    justify-content: center;
    align-items: stretch;
    width: 100%!important;
    padding: 0;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    margin-bottom: 0.6rem;
    border-radius: 8px !important;
    color: rgb(97, 90, 235);
    background-color: rgb(225, 223, 255);
    box-shadow: 10px 40px 40px 5px rgb(236, 236, 236) !important;
}

#restore-password-send-email-button {
    top: 3em;
    left: 0%!important;
    font-size: 1.2em !important;
    position: relative;
    justify-content: center;
    align-items: stretch;
    width: 100%!important;
    padding: 0;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    margin-bottom: 0.6rem;
    border-radius: 8px !important;
    box-shadow: 10px 30px 30px 5px rgb(236, 236, 236) !important;
}

#restore-password-back-button {
    top: 4em;
    left: 0%;
    font-size: 1.1em !important;
    position: relative;
    justify-content: center;
    align-items: stretch;
    width: 100%!important;
    padding: 0;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    margin-bottom: 0.6rem;
    color: rgb(0, 0, 0);
    background-color: rgba(255, 255, 255, 0);
}

#restore-password-back-icon {
    vertical-align: middle
}

.input-login {
    background: white !important;
    border: none !important;
}