
.like-top{
  margin-top:10px;
}

.event-left
{
    float: none;
}
.event-row
{
  display: inline-flex;
}
.event-Card
{
    /*box-shadow: 0 0 10px rgba(0,0,0,0.5); */
    background: white;
    padding: 5px;
    height: auto;
    margin: 5px;
    margin-bottom: 10px;
    border-radius: 10px;

}
.event-List
{
    margin-top: 0vh;

}
.event-Table
{
    position: none;
    width: 100%;

}
.event-Heder-Card
{
    font-size: calc(10px);
    text-align: left;
    color: #767676;

}
.event-complited
{
  font-size: calc(15px);
  text-align: left;
  color: rgb(10 10 10);
  font-weight: bold;

}

  .event-star-column {
    float: left;
    width: 24px;
  }
  .event-column {
    float: left;
    width: 20px;
  }
  .event-row:after {
    content: "";
    display: table;
    clear: both;
    vertical-align: middle;
  }
  
  /* Clear floats after the columns */
  .event-star-row:after {
    content: "";
    display: table;
    clear: both;
   
  }
  .event-Empty
  {
    height: 45px;
  }
  .event-Empty1
  {
    height: 15px;
  }
  .td1
  {
    
    min-width: 100px;
    vertical-align: top;
  }
  .td2
  {
    font-size: 12px;
    min-width: 100px;
    vertical-align: text-top;
  }
  .td3
  {
   
    min-width: 120px;
    max-width: 150px;
    vertical-align: text-top;
  }
  .td4
  {
    vertical-align: text-top;
  }
  .td5
  {
    position: none;
    min-width: 50px;
  }
  .img-comment
  {
    width: 17px;
    height: 17px;

  }
  .img-heart
  {
    width: 17px;
    height: 17px;

  }
  .img-style
  {
    width: 140px;
    height: 115px;
    border-radius: 10px;
  }
  .img-style-camper{
    margin-right: 5px;
    width: 20px;
    height: 20px;
    border-radius: 30px;
    vertical-align: -5px;
  }
  .img-star-style
  {
    width: 17px;
    height: 17px;
  }

  .text-Heder
  {
    margin-left: 30px;
  }
  .event-List
  {
      margin: 15px;
  }
  @media (max-width: 550px) 
  { 
    
    .event-left
    {
        float: none;
    }
    .event-Heder-Card
    {
        font-size: calc(10px);
        text-align: left;
        color: #767676;

    }
  }
    .NavBarItem {
      font-size: 8px;
      
    }
    .event-left
    {
        float: left;
    }
    .event-star-row
    {
      padding-left: 10px;
    }
    .event-Heder-Card
    {
      padding-left: 10px;
    }
    .event-complited
    {
      padding-left:10px;
    }
    .td2
    {
      font-size: 12px;
      min-height: 170px;
    }
    .td4
    {
      display: -webkit-inline-box;
    }
    .td5
    {
      display: inline-table;
      font-size: 10px;
    }
    .event-Table
    {
      position: relative;
    }

    .event-star-column {
      float: left;
      width: 12px;
    }
    .img-star-style
    {
      width: 10px;
      height: 10px;
    }
    .img-comment
    {
      width: 25px;
      height: 25px;

    }
    .img-heart
    {
      width: 25px;
      height: 25px;
      

    }
    .img-style
    {
      width: 100%;
      border-radius: 10px;
      max-width: 150px;
      max-height: 150px;
    }
   

    .hidden
    {
      display: none;
    }

     /*Работа с таблицей*/
    /* Force table to not be like tables anymore */
    table, thead, tbody, th, td, tr {
      display: block;
    }

      /* Hide table headers (but not display: none;, for accessibility) */
    thead tr {
        position: absolute;
        top: -9999px;
        left: -9999px;
    }

  .table > thead > tr > th,
  .table > tbody > tr > th,
  .table > tfoot > tr > th,
  .table > thead > tr > td,
  .table > tbody > tr > td,
  .table > tfoot > tr > td {
      border-top: 0px solid #dddddd;
  }

.loader-container {
  width: 100%;
  height: 60px;
  position: fixed;
  background: transparent
      url("https://media.giphy.com/media/8agqybiK5LW8qrG3vJ/giphy.gif") center
      no-repeat;
  z-index: 1;
}

