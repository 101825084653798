.settingsText
{
    color: #868686;
    box-shadow: 0 0 10px rgb(0 0 0 / 50%);
    width: 80%;
    min-height: 60px;
    text-align: left;
    padding-top:5px;
    padding-left: 5px;
    padding-right: 5px;
    

}